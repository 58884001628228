<template>
    <div>
        <b-card>
            <div class="m-2">
                <b-row class="mb-2">
                    <b-col cols="12">
                        <b-table
                            ref="refUserListTable"
                            class="position-relative"
                            :items="items"
                            responsive
                            :fields="tableColumns"
                            primary-key="id"
                            :sort-by.sync="filters.orderBy"
                            show-empty
                            :empty-text="loadingString"
                            :sort-desc.sync="isSortDirDesc"
                        >

                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                            :src="$basePath+data.item.user.fileName"
                                            :text="avatarText(data.item.user.name)+avatarText(data.item.user.surname)"
                                            :variant="`light-primary`"
                                        />
                                    </template>
                                    <div>{{ data.item.user.name+' '+data.item.user.surname }}</div>
                                    <div><a :href="'tel:'+data.item.user.gsm"><small class="text-muted">{{ data.item.user.gsm }}</small></a></div>
                                </b-media>
                            </template>
                            <template #cell(orderDetail)="data">
                                <div><small>Toplam ürün:</small> <small class="text-muted">{{ data.item.totalQty }}</small></div>
                                <div><small>Toplam ücret:</small> <small class="text-muted">{{data.item.total}}₺</small></div>
                                <div><small>Sipariş tarihi:</small> <small class="text-muted">{{ data.item.createdAt | moment("DD.MM.YYYY HH:mm:ss") }}</small></div>
                                <b-badge :variant="statusVariants[data.item.status]">{{ statusOptions[data.item.status] }}</b-badge>
                            </template>
                            <template #cell(actions)="data">
                                <b-button variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="showModal(data.item.id)">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </template>
                            <template #cell(id)="data">
                                <b-link @click="showModal(data.item.id)">{{ data.item.id }}</b-link>
                            </template>

                        </b-table>
                    </b-col>
                </b-row>



                <!--Pagination && Row Count-->
                <b-row class="mt-2 flex-wrap flex-md-row-reverse">
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center justify-content-md-end mb-2 mb-md-0">
                        <b-pagination
                            v-model="filters.page"
                            :total-rows="totalItems"
                            :per-page="filters.perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            :disabled="loading"
                            :class="{hidden:totalItems===999999}"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-0">
                        <label>Tek ekranda</label>
                        <v-select
                            v-model="filters.perPage"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            disabled
                        />
                        <label>kayıt göster</label>
                    </b-col>
                </b-row>

            </div>
        </b-card>

        <b-modal ref="orderDetailModal" title="Sipariş Detay" hide-footer centered size="lg">
<!--            {{ modalItem }}-->
            <h6>Ürünler</h6>
            <b-table :items="modalItem.basket" :fields="basketColumns">
                <template #cell(product)="data">
                    <div v-if="data && data.item.id">
                        <div>#{{ data.item.productId }} {{ data.item.productTitle }}</div>
                        <small class="text-muted">Varyant: {{ data.item.options.optionUnit+ ' ' + data.item.options.unitTypeTitle }} </small>
                    </div>
                </template>
                <template #cell(qty)="data">
                    <div class="text-center" v-if="data && data.item.id"> {{ data.item.qty }} </div>
                </template>
                <template #cell(price)="data">
                    <div v-if="data">
                        <div v-if="data.item.id">
                            <small class="text-muted"> {{ data.item.priceWithTax+'₺' }} x {{ data.item.qty }} </small>
                            <div> {{ data.item.total+'₺' }} </div>
                        </div>
                        <div v-else class="d-flex justify-content-end flex-column">
                            <div><span>Ara toplam:</span> <span class="text-muted">{{ modalItem.subTotal }}</span></div>
                            <div><span>Vergiler:</span> <span class="text-muted">{{ modalItem.totalTax }}</span></div>
                            <div><span>Toplam:</span> <span class="text-muted">{{ modalItem.total }}</span></div>
                            <div><span>Banka komisyonu:</span> <span class="text-muted">{{ modalItem.bank_commission+' ('+modalItem.bank_commission_percent+'%)' }}</span></div>
                            <div><span>Petsomy komisyonu:</span> <span class="text-muted">{{ modalItem.petsomy_commission+' ('+modalItem.petsomy_commission_percent+'%)' }}</span></div>
                            <div><span>Hakediş:</span> <span class="text-muted">{{ modalItem.progress_payment }}</span></div>
                        </div>
                    </div>
                </template>
            </b-table>

            <h6 class="mb-2">Kullanıcı & Adres Bilgileri</h6>
            <div v-if="modalItem.id">
                <dl class="row">
                    <dt class="col-sm-3 text-right">Müşteri bilgileri: </dt>
                    <dd class="col-sm-9">{{ 'Ad Soyad: '+modalItem.user.name + ' ' + modalItem.user.surname + ' Telefon: ' + modalItem.user.gsm }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3 text-right">Adres</dt>
                    <dd class="col-sm-9">{{ modalItem.address.address }} {{ modalItem.address.street.name }} Sk. {{ modalItem.address.district.name }} Mah. {{ modalItem.address.county.name }}/{{ modalItem.address.city.name }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3 text-right">Adres tarifi</dt>
                    <dd class="col-sm-9">{{ modalItem.address.directions }}</dd>
                </dl>
            </div>

            <h6 class="mb-2">Sipariş Bilgileri</h6>
            <div v-if="modalItem.id">
                <dl class="row">
                    <dt class="col-sm-3 text-right">Sipariş Tarihi </dt>
                    <dd class="col-sm-9">{{ modalItem.createdAt | moment("DD.MM.YYYY HH:mm:ss") }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3 text-right">Sipariş Durumu </dt>
                    <dd class="col-sm-9">{{ statusOptions[modalItem.status] }}</dd>
                </dl>
                <div class="d-flex justify-content-center pb-3">
                    <b-button-group>
                        <b-button :variant="modalItem.status !== '1'? 'outline-secondary':'outline-primary'" size="sm" type="button" @click="setStatus(modalItem.id, 3)" :disabled="modalItem.status !== '1'">
                            <feather-icon icon="CheckCircleIcon" />
                            Siparişi onayla
                        </b-button>
                        <b-button :variant="modalItem.status !== '1'? 'outline-secondary':'outline-warning'" size="sm" type="button" @click="setStatus(modalItem.id, 4)" :disabled="modalItem.status !== '1'">
                            <feather-icon icon="XIcon" />
                            Siparişi reddet
                        </b-button>
                        <b-button :variant="modalItem.status !== '3'? 'outline-secondary':'outline-info'" size="sm" type="button" @click="setStatus(modalItem.id, 5)" :disabled="modalItem.status !== '3'">
                            <feather-icon icon="TruckIcon" />
                            Dağıtıma çıkarıldı
                        </b-button>
                        <b-button :variant="modalItem.status !== '5'? 'outline-secondary':'outline-success'" size="sm" type="button" @click="setStatus(modalItem.id, 6)" :disabled="modalItem.status !== '5'">
                            <feather-icon icon="CheckIcon" />
                            Teslim edildi
                        </b-button>
                        <b-button :variant="modalItem.status !== '6'? 'outline-secondary':'outline-danger'" size="sm" type="button" @click="setStatus(modalItem.id, 7)" :disabled="modalItem.status !== '6'">
                            <feather-icon icon="XIcon" />
                            İade edildi
                        </b-button>
                    </b-button-group>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'

import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BModal,

    VBModal,

} from 'bootstrap-vue'
import store from "@/store";
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import Ripple from "vue-ripple-directive"

export default {
    name: 'list',
    data(){

        return {
            items: [],
            loading: false,
            totalItems: 999999,

            filters:{
                perPage: 10,
                page: 1,
                orderBy: 'id',
                orderType: 'DESC',

            },
            modalItem:{
                basket:[]
            },
            perPageOptions: [10, 10, 50, 100],
            isSortDirDesc: true,
            tableColumns: [
                { key: 'id', label:'#',  sortable: true },
                { key: 'user', label:'Kullanıcı' },
                { key: 'orderDetail', label:'Sipariş Detayları' },
                { key: 'actions', label:'Detaylar' },
            ],
            basketColumns: [
                { key: 'product', label: 'Ürün' },
                { key: 'qty', label: 'Adet' },
                { key: 'price', label: 'Fiyat' }
            ],
            avatarText,
            statusOptions: {
                0: 'Ödeme Bekleniyor',
                1: 'Ödendi, Onay Bekleniyor',
                2: 'Ödeme iptal edildi.',
                3: 'Onaylandı, Teslimat Bekleniyor',
                4: 'Sipariş Reddedildi',
                5: 'Kurye Dağıtımında',
                6: 'Teslim Edildi',
                7: 'İade Edildi'
            },
            statusVariants: {
                0: 'info',
                1: 'primary',
                2: 'danger',
                3: 'info',
                4: 'danger',
                5: 'warning',
                6: 'success',
                7: 'danger'
            }
        }
    },

    components:{
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BPagination,
        BModal,
        BButtonGroup,

        vSelect,
    },
    directives:{
        'b-modal': VBModal,
        Ripple
    },
    created(){
        this.drawItems()
    },
    computed:{
        loadingString(){
            return this.loading?'Siparişler yükleniyor. Lütfen bekleyin.':'Aramanızla eşleşen sipariş bulunamadı. Lütfen aramanızı daraltarak tekrar deneyin.'
        }
    },
    watch:{
        isSortDirDesc(){
            this.filters.orderType = this.isSortDirDesc?'DESC':'ASC'
        },
        '$route.params.page'(){
            this.filters.page = this.$route.params.page
        },
        'filters.page'(){
            this.$router.push({name:'orders_paged', params: {page: this.filters.page}})
            this.drawItems()
        },
        'filters.orderBy'(){
            this.drawItems()
        },
        'filters.orderType'(){
            this.drawItems()
        },
        'filters.perPage'(){
            this.drawItems()
        }
    },
    methods:{
        drawItems(){
            let t = this

            if(!t.loading){
                t.loading = true
                store.commit('app/SET_LOADING', true)
                t.$http.get('order/c/listOrders/'+t.filters.page).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: res.message
                                },
                            })
                        }

                        if(res.total && !data.length){
                            t.filters.page=1
                            t.totalUsers = res.total
                        }else{
                            store.commit('app/SET_LOADING', false)
                            t.totalItems = res.total
                            if(data.length){
                                for(let i = 0; i < data.length; i++){
                                    let bskt = data[i].basket
                                    data[i].totalQty = 0
                                    for(let f = 0; f < bskt.length; f++){
                                        data[i].totalQty += parseFloat(bskt[f].qty)
                                    }
                                }
                            }
                            t.items = data
                        }
                    }else{
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Hata!',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    text: res.message
                                },
                            })
                        }
                        if(res.error){

                        }

                        store.commit('app/SET_LOADING', false)
                    }

                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loading = false
                    store.commit('app/SET_LOADING', false)
                })
            }

        },
        findItemIndex(id){
            return this.items.findIndex(f=>{return f.id === id})
        },
        showModal(id){
            let itemIndex = this.findItemIndex(id)
            this.modalItem = structuredClone(this.items[itemIndex])
            this.modalItem.basket.push({
                id: false,
            })
            this.$refs['orderDetailModal'].show()
        },
        setStatus(id, status){
            let t = this
            this.$swal({
                title: 'Emin misiniz?',
                text: "Sipariş durumunu "+t.statusOptions[status]+' olarak güncellemek istediğinize emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Güncelle!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return t.$http.post('order/setStatus/' + id, {status}).then(result => {
                            if (result.data.status) {

                                t.modalItem.status = status.toString()
                                let fIndex = this.findItemIndex(id)
                                t.items[fIndex].status = status.toString()
                            }
                            return result.data
                        })
                }
            }).then(result => {
                if (result.value) {
                    if (result.value.status) {
                        //Başarılı mesajı
                        if(result.value.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: result.value.message
                                },
                            })
                        }
                    } else {
                        //Hata mesajı w/result.value.message
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Hata!',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                text: result.value.message
                            },
                        })
                    }
                }
            })

        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
